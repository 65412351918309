<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5 mb-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudo_aguas_title_01.png"
            alt=""
          />
        </div>
      </v-row>
    </section>
    <section class="container">
      <img src="../../assets/conteudo_aguas_lama_01.png" alt="" />
    </section>

    <section class="container mt-5 mb-5">
      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <v-btn :to="{ name: '' }" color="#EDBD43">
            <strong>Conhecer “Garimpeiros”</strong>
            ></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
export default {
  components: { Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Águas /",
          href: "conteudos_tematicos_aguas",
        },
        {
          text: "Lama",
          href: "conteudos_tematicos_aguas_lama",
        },
      ],
    };
  },
};
</script>

<style></style>
